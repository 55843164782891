/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./QuestionnaireEditor.vue?vue&type=template&id=32c0f512&scoped=true&lang=pug&"
import script from "./QuestionnaireEditor.vue?vue&type=script&lang=ts&"
export * from "./QuestionnaireEditor.vue?vue&type=script&lang=ts&"
import style0 from "./QuestionnaireEditor.vue?vue&type=style&index=0&id=32c0f512&lang=scss&scoped=true&"
import style1 from "./QuestionnaireEditor.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c0f512",
  null
  
)

export default component.exports